footer{
    padding: 20px 0;
    background-color: $color-white;
    .container{
        .split{
            .side{
                &:first-child{
                    display: none;
                }
                &:last-child{
                    max-width: 420px;
                    .form{
                        margin-bottom: 60px;
                        p{
                            text-transform: uppercase;
                            font-size: 16px;
                            color: $color-black;
                            font-weight: 600;
                            margin-bottom: 20px;
                        }
                        form{
                            position: relative;
                            label{
                                position: absolute;
                                z-index: 0;
                                color: $color-black;
                            }
                            input,
                            button{ 
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                appearance: none;
                                position: relative;
                                z-index: 1;
                                background-color: $color-white;
                                border: none;
                                border-bottom: solid 2px $color-silk;
                                color: $color-black;
                                font-size: 16px;
                                border-radius: 0 !important;
                                text-transform: uppercase;
                                padding: 4px !important;
                                &::placeholder{
                                    color: $color-black;
                                }
                                @include hover-focus{
                                    outline: none;
                                }
                            }
                        }
                        .form-error,
                        .form-success{
                            p{
                                font-size: 12px;
                            }
                        }
                        .form-error{
                            margin-top: 10px;
                        }
                    }
                    .social{
                        margin-bottom: 30px;
                        a{
                            color: $color-silk;
                            font-size: 20px;
                            margin-right: 16px;
                            display: inline-block;
                            transition: all .3s;
                            &:last-child{
                                margin-right: 0;
                            }
                            @include hover-focus{
                                transform: translateY(-2px);
                            }
                        }
                    }
                    .copyright{
                        p{
                            font-size: 10px;
                            color: $color-black;
                            text-transform: uppercase;
                            line-height: 1.4em;
                            a{
                                color: $color-black;
                                @include hover-focus{
                                    color: $color-purple;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @include phone-up{
        padding: 50px 0 40px;
        .container{
            .split{
                @include flex(row, space-between, center);
                .side{
                    width: calc( 50% - 20px );
                    &:first-child{
                        display: block;
                        img{
                            display: block;
                            max-width: 100%;
                            height: 400px;
                            object-fit: contain;
                        }
                    }
                    &:last-child{
                        @include flex (column, flex-end, flex-end);
                        .form{
                            width: 370px;
                            form{
                                .form-group{
                                    width: 100%;
                                    @include flex (row, space-between, center);
                                    input{
                                        width: calc( 100% - 50px );
                                        padding: 6px 0;
                                    }
                                    button{
                                        padding: 6px;
                                    }
                                }
                            }
                        }
                        .copyright{
                            p{
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
    }
}