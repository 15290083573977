#shop {
  padding: 60px 0;

  .container {
    text-align: center;

    .btn {
      border-bottom: solid 1px $color-silk;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-align: center;
      color: $color-black;
      display: inline-block;
      padding: 0 6px 6px;
    }

    h2 {
      -webkit-text-stroke: 1px $color-black;
      color: transparent;
      text-transform: uppercase;

      &.section-heading-h {
        display: block;
        max-height: 30px;
        margin: 0 auto 40px;
        text-align: center;
        font-size: 42px;
        letter-spacing: 4px;
        margin-bottom: 40px;
      }

      &.section-heading-v {
        display: none;
      }
    }

    .wrapper {
      margin-bottom: 80px;

      .products {
        @include flex(column, center, center);
        gap: 40px;

        .product {
          @include flex(column, center, center);

          img {
            width: 100%;
            max-width: 300px;
          }

          p {
            text-align: center;
            color: $color-black;
            font-size: 20px;
            letter-spacing: 2px;
            text-transform: uppercase;
          }

        }
      }
    }
  }

  @include tablet-up {
    padding: 0 0 120px;

    .container {
      position: relative;

      h2 {
        -webkit-text-stroke: 2px $color-black;

        &.section-heading-h {
          display: none;
        }

        &.section-heading-v {
          writing-mode: vertical-rl;
          text-orientation: revert;
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          margin: 0;
          font-size: 150px;
          transform: rotate(180deg);
        }
      }

      .wrapper {
        .products {
          padding-left: 180px;

          @include flex(row, space-between, initial);
          gap: 30px;

          .product {
            flex: 1;

            img {
              max-width: 100%;
              max-height: 400px;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}