@font-face {
    font-family: 'Proxima Nova';
    src: url('../../dist/font/ProximaNova-Regular.woff2') format('woff2'),
        url('../../dist/font/ProximaNova-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../../dist/font/ProximaNova-Semibold.woff2') format('woff2'),
        url('../../dist/font/ProximaNova-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

h1, h2, h3, h4{
    margin: 0;
}