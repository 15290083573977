$fa-font-path: "../font";
$html-font-size: 16;
$color-white: #fff;
$color-black: #47464B;
$color-purple-light: #e2dfed;
$color-purple: #b397ac;
$color-purple-dark: #9b7892;
$color-brick: #cc8479;
$color-pink: #C777BB;

$color-dorado: #6C5E4B;
$color-sandrift: #A79075;
$color-silk: #C0AE96;
$color-ebony-clay: #2C232B;