.bio {
  background-image: url('../../dist/img/refresh-2/clothbg.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 40px 0;
  background-position: center left;

  .container {
    .split {
      background-color: #00000033;
      padding: 10px;

      .side {
        &:first-child {
          margin-bottom: 20px;

          h2 {
            -webkit-text-stroke: 1px $color-white;
            color: transparent;
            text-transform: uppercase;

            &.heading-h {
              display: block;
              max-height: 30px;
              margin: 0 auto 40px;
              text-align: center;
              font-size: 42px;
              letter-spacing: 4px;
              margin-bottom: 40px;
            }

            &.heading-v {
              display: none;
            }
          }
        }

        &:last-child {
          h2 {
            display: none;
          }

          img {
            display: none;
          }

          p {
            font-size: 16px;
            line-height: 1.6em;

            &.bio-extra {
              display: none;
            }
          }

          button {
            margin-top: 40px;
            background-color: transparent;
            border: none;
            border-bottom: solid 1px $color-sandrift;
            font-size: 16px;
            color: $color-white;
            text-transform: uppercase;
            font-weight: 600;
            display: block;
            width: 130px;
            text-align: left;
            padding-left: 0;
          }
        }
      }
    }
  }

  @include phone-up {
    padding: 120px 0;
    background-position: center;

    .container {
      .split {
        @include flex (row, space-between, center);
        gap: 30px;
        background-color: transparent;

        .side {
          flex: 1;

          &:first-child {
            h2 {
              -webkit-text-stroke: 2px $color-black;

              &.heading-h {
                display: none;
              }

              &.heading-v {
                writing-mode: vertical-rl;
                text-orientation: revert;
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                margin: 0;
                font-size: 140px;
                transform: rotate(180deg);
              }
            }
          }

          &:last-child {
            h2 {
              -webkit-text-stroke: 1px $color-ebony-clay;
              color: transparent;
              text-transform: uppercase;

              &.heading-h {
                display: block;
                margin: 0 auto 40px;
                text-align: left;
                font-size: 140px;
                letter-spacing: 4px;
                margin-bottom: 40px;
              }

              &.heading-v {
                display: none;
              }
            }

            img {
              display: block;
              margin-bottom: 30px;
            }

            p {
              color: $color-black;
            }

            button {
              color: $color-black
            }
          }
        }
      }
    }
  }
}

.full-bio {
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.822);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;

  .container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .wrapper {
      background-color: $color-white;
      padding: 40px;
      color: $color-black;
      position: relative;

      button {
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 30px;
        background-color: $color-black;
        color: $color-white;
        border: none;
      }

      .content {
        max-height: 400px;
        overflow: scroll;

        h2 {
          margin-bottom: 20px;
          text-transform: uppercase;
        }

        p {
          margin-bottom: 20px;
        }
      }
    }
  }
}