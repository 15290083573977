.hero {
  overflow: hidden;
  background-color: transparent;

  #bg-hero {
    width: 100%;
    object-fit: cover;
    height: 80vw;
    display: none;

    @include phone-up {
      height: 100vh;
      display: block;
    }
  }

  #bg-hero-mobile {
    width: 100%;
    object-fit: cover;
    // height: 80vw;

    @include phone-up {
      // height: 100vh;
      display: none;
    }
  }
}