.videos {
  padding: 40px 0;
  background-color: $color-white;

  .container {
    position: relative;

    .btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 70px;

      .btn {
        border-bottom: solid 1px $color-silk;
        text-transform: uppercase;
        letter-spacing: 2px;
        text-align: center;
        color: $color-black;
        display: inline-block;
        padding: 0 6px 6px;
        margin: 0 auto;
      }
    }

    .video-slider-small {
      padding: 0 30px;
    }

    .video {
      display: block;
      width: 100%;
      padding: 10px;

      img {
        width: 100%;
        margin-bottom: 20px;
        aspect-ratio: 5/8;
        object-fit: cover;
      }

      p {
        color: $color-black;
        text-transform: uppercase;
        margin: 0;
        letter-spacing: 2px;
        text-align: center;
        line-height: 1.5em;
      }

      .video-link {
        display: block;
        display: block;
        position: relative;

        // &::after {
        //   font-family: Font Awesome\ 5 Pro;
        //   font-weight: 900;
        //   content: "\f04b";
        //   position: absolute;
        //   font-size: 80px;
        //   top: calc(50% - 40px);
        //   left: calc(50% - 40px);
        //   transition: all .4s;
        // }
      }
    }

    h2 {
      -webkit-text-stroke: 1px $color-black;
      color: transparent;
      text-transform: uppercase;

      &.section-heading-h {
        display: block;
        max-height: 30px;
        margin: 0 auto 40px;
        text-align: center;
        font-size: 42px;
        letter-spacing: 4px;
        margin-bottom: 40px;
      }

      &.section-heading-v {
        display: none;
      }
    }
  }

  @include tablet-up {
    padding: 200px 0;

    .container {

      .video-slider-small {
        display: block;
        padding: 0 20px;
        margin-right: 200px;

        .slick-arrow {
          top: calc(50% - 20px);
        }

        .video {
          padding: 10px;

          img {
            aspect-ratio: 5/8;
            margin-bottom: 0;

          }
        }
      }

      h2 {
        -webkit-text-stroke: 2px $color-black;

        &.section-heading-h {
          display: none;
        }

        &.section-heading-v {
          writing-mode: vertical-rl;
          text-orientation: revert;
          display: block;
          position: absolute;
          right: 0;
          top: 0;
          margin: 0;
          font-size: 150px;
          transform: rotate(180deg);
        }
      }
    }
  }
}