@import "variables";
@import "framework/framework";
@import "framework/fonts";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/slick-carousel/slick/slick.scss";
@import "header";
@import "footer";
@import "sections/hero";
@import "sections/music";
@import "sections/bio";
@import "sections/videos";
@import "sections/tour";
@import "sections/shop";

html {
	background: $color-black;
	font-family: Proxima Nova, Arial, sans-serif;
	font-size: 16px;
	line-height: 1;
	scroll-behavior: smooth;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $color-white;
}

a {
	color: $color-white;

	@include hover-focus {
		color: $color-white;
	}
}

section{
	background-color: $color-white;
}

.slick-arrow{
	background-color: transparent;
	border: none;
	padding: 0;
	line-height: 1;
	color: $color-silk;
	font-size: 20px;
	position: absolute;
	top: calc( 50% - 50px );
	&.slick-prev{
		left: 0;
	}
	&.slick-next{
		right: 0
	}
	&.slick-disabled{
		opacity: .25;
	}
}

.slick-dots{
	margin: 30px 0 0 0;
	list-style: none;
	padding: 0;
	@include flex (row, center, center);
	li{
		margin-right: 10px;
		&:last-child{
			margin-right: 0;
		}
		button{
			background-color: $color-silk;
			border: none;
			color: transparent;
			padding: 0;
			line-height: 0;
			width: 10px;
			height: 10px;
			border-radius: 10px;
			transition: all .3s;
			opacity: .5;
		}
		&.slick-active{
			button{
				background-color: $color-silk;
				opacity: 1;
			}
		}
	}
}