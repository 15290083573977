#music {
  background-color: $color-white;
  padding: 40px 0;
  color: $color-black;

  .container {

    .heading-h {
      -webkit-text-stroke: 1px $color-black;
      color: transparent;
      text-transform: uppercase;
      display: block;
      max-height: 30px;
      margin: 0 auto 40px;
      text-align: center;
      font-size: 42px;
      letter-spacing: 4px;
      margin-bottom: 40px;
    }

    .music-slider { 
      padding: 0 30px;
      position: relative;

      .split {
        @include flex (column-reverse, center, center);
        // gap: rems(20);

        .side {
          &:first-child {
            h2 {
              -webkit-text-stroke: 1px $color-black;
              color: transparent;
              text-transform: uppercase;

              &.heading-v {
                display: none;
              }
            }

            .cover-info {
              text-align: center;
              text-transform: uppercase;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              h3 {
                font-size: 48px;
                letter-spacing: 1px;
                color: $color-black;
              }

              span {
                display: inline-block;
                font-size: 18px;
                // margin-bottom: 40px;
                letter-spacing: 3px;
                font-weight: 500;
                color: $color-black;
              }

              p {
                font-size: 20px;
                // margin-bottom: 40px;
                letter-spacing: 8px;
                color: $color-black;
                font-weight: 600;
              }

              .btn {
                color: $color-black;
                background-color: $color-silk;
                font-size: 30px;
                padding: 16px 0;
                width: 100%;
                max-width: 250px;
                transition: all .4s ease-in-out;
                margin: 40px 0;

                @include hover-focus {
                  opacity: .7;
                }
              }

              .buttons {
                margin-bottom: 40px;
                @include flex(row, center, center);
                flex-wrap: wrap;
                column-gap: 20px;
                row-gap: 10px;

                a {
                  color: $color-pink;
                  font-size: 30px;
                  border: solid 3px $color-pink;
                  padding: 0;
                  border-radius: 60px;
                  width: 60px;
                  height: 60px;
                  @include flex(column, center, center);
                  margin: 0;

                  &.image {
                    border: none;

                    img {
                      height: 60px;
                      width: 60px;
                      object-fit: contain;
                      object-position: center;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @include tablet-up {
    padding: 140px 0;

    .container {
      .music-slider {
        .split {
          flex-direction: row;
          justify-content: space-between;
          align-items: inherit;
          // max-width: 1032px;
          margin: 0 auto;
          position: relative;
          padding-right: 164px;
          gap: 20px;

          .side {
            flex: 1;

            &:first-child {
              display: flex;

              h2 {
                -webkit-text-stroke: 2px $color-black;

                &.heading-h {
                  display: none;
                }

                &.heading-v {
                  writing-mode: vertical-rl;
                  text-orientation: revert;
                  display: block;
                  position: absolute;
                  right: 0;
                  top: 0;
                  margin: 0;
                  font-size: 140px;
                  transform: rotate(180deg);
                }
              }

              .cover-info {
                width: 100%;

                h3 {
                  margin-bottom: 30px;
                }

                p {
                  margin-bottom: 40px;
                }

                .buttons {
                  margin-bottom: 0;
                  max-width: 280px;
                  margin: 0 auto;

                  a {
                    @include hover-focus {
                      color: $color-purple;
                      border-color: $color-purple;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}