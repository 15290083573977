#tour {
  background-color: $color-silk;

  .container {
    display: flex;
    align-items: stretch;

    @include phone-down {
      justify-content: center;
    }

    .tour-wrapper {
      padding: 80px 0;

      @include phone-down {
        padding: 40px 0;
      }

      .content {
        position: relative;
        padding-left: 180px;
        max-width: 460px;
        width: 100%;

        @include phone-down {
          padding-left: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        h2 {
          -webkit-text-stroke: 2px $color-black;
          color: transparent;
          text-transform: uppercase;
          margin-bottom: 20px;
          font-size: 40px;
          text-align: center;


          &.heading-h {
            display: none;
          }

          &.heading-v {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            writing-mode: vertical-rl;
            text-orientation: revert;
            display: block;
            position: absolute;
            margin: 0;
            font-size: 140px;
            transform: rotate(180deg);
          }

          @include phone-down {
            &.heading-h {
              display: block;
            }

            &.heading-v {
              display: none;
            }
          }
        }

        .scroll-container {
          overflow-y: scroll;
          max-height: 850px;
        }
      }

      .btn {
        display: inline-block;
        border-bottom: 1px solid $color-black;
        color: $color-black;
        text-transform: uppercase;
        padding: 4px 6px;
        margin: 44px 0 0 24px;
        font-weight: 700;
      }
    }

    .tour-image-wrapper {
      margin-right: calc(-50vw + 50%);
      flex: 1;
      max-height: 1110px;

      // padding: 80px 0;

      // @include phone-down {
      //   padding: 40px 0;
      // }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: bottom center;
      }

      @include phone-down {
        display: none;
      }
    }
  }
}


#tour {

  .seated-events-table {
    overflow-y: scroll;
    max-height: 850px;
    color: $color-black;
    border: none;
    padding-right: 12px;

    .seated-event-row {
      display: flex;
      flex-direction: column;
      border: none;

      @include phone-down {
        align-items: center;
        flex-direction: row;
      }

      .seated-event-description-cells {

        .seated-event-date-cell {
          @include phone-down {
            text-align: left;
          }
        }

        .seated-event-venue-cell {
          display: flex;
          flex-direction: column;

          .seated-event-venue-name {
            width: 100%;

            @include phone-down {
              text-align: left;
            }
          }

          .seated-event-venue-location {
            width: 100%;

            @include phone-down {
              text-align: left;
            }
          }
        }

        .seated-event-details-cell {}
      }

      .seated-event-link-cells {
        display: flex;
        flex-direction: column-reverse;

        .seated-event-link-cell {
          width: 100%;
          max-width: 120px;

          a {
            margin: 0;
            background-color: transparent;
            color: $color-black;
            border: none;
            border-bottom: 2px solid $color-black;
            padding: 6px 0;
            text-align: left;
            font-weight: 700;
            letter-spacing: .2em;
          }
        }
      }
    }
  }
}

#tour-page {
  background-color: $color-silk;

  .container {
    .tour-wrapper {
      width: 100%;
      padding: 80px 0;

      @include phone-down {
        padding: 40px 0;
      }

      .content {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
          -webkit-text-stroke: 2px $color-black;
          color: transparent;
          text-transform: uppercase;
          margin-bottom: 20px;
          font-size: 64px;
          text-align: center;
        }
      }
    }

    .tour-image-wrapper {
      margin-right: calc(-50vw + 50%);
      flex: 1;

      // padding: 80px 0;

      // @include phone-down {
      //   padding: 40px 0;
      // }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: bottom center;
      }

      @include phone-down {
        display: none;
      }
    }
  }
}


#tour-page {
  .scroll-container {
    width: 100%;
  }

  .seated-events-table {
    color: $color-black;
    border: none;

    .seated-event-row {
      display: flex;
      justify-content: space-between;
      border: none;

      @include phone-down {
        padding-bottom: 40px;
      }

      .seated-event-description-cells {
        display: flex;
        gap: 10px;

        @include phone-down {
          flex-direction: column;
        }

        >div {
          flex: 1;
        }

        .seated-event-date-cell {}

        .seated-event-venue-cell {
          display: flex;
          flex-direction: column;

          .seated-event-venue-name {
            width: 100%;
          }

          .seated-event-venue-location {
            width: 100%;
          }
        }

        .seated-event-details-cell {}
      }

      .seated-event-link-cells {
        display: flex;
        flex-direction: column-reverse;

        @include phone-down {
          align-items: center;
        }

        .seated-event-link-cell {
          width: 100%;
          max-width: 120px;

          a {
            margin: 0;
            background-color: transparent;
            color: $color-black;
            border: none;
            border-bottom: 2px solid $color-black;
            padding: 6px 0;
            text-align: left;
            font-weight: 700;
            letter-spacing: .2em;
          }
        }
      }
    }
  }
}