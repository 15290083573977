header {
  background-color: $color-white;
  padding: 20px 0;

  .container {
    @include flex (row, space-between, center);
    flex-wrap: wrap;

    nav {
      display: none;
      position: relative;
      width: 100%;
      text-align: right;

      .menu {
        padding-top: 20px;
        width: 100%;
        text-align: center;

        .nav-link {
          color: $color-black;
          display: block;
          text-align: center;
          padding: 10px;
          font-size: 16px;
          text-transform: uppercase;
          transition: all .4s;

          &:last-child {
            border-bottom: none;
          }

          @include hover-focus {
            color: $color-purple;
            transform: translateY(-2px);
          }
        }
      }
    }

    .menu-toggle {
      display: inline-block;
      width: 30px;
      height: 20px;
      border: none;
      border-top: solid 2px $color-silk;
      border-bottom: solid 2px $color-silk;
      padding: 0;
      position: relative;
      transition: all .4s;
      background-color: transparent;
      transform: translateY(-4px);

      &::after,
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background-color: $color-silk;
        position: absolute;
        top: calc(50% - 1px);
        transition: all .4s;
      }

      &.active {
        border-color: transparent;

        &::after {
          transform: rotate(45deg);
        }

        &::before {
          transform: rotate(-45deg);
        }
      }

      span {
        text-transform: uppercase;
        position: absolute;
        bottom: -16px;
        left: 1px;
        font-size: 10px;
        font-weight: 700;
        display: block;
        color: $color-silk;
      }
    }

    .social {
      @include flex(row, inherit, center);

      a {
        color: $color-black;
        display: inline-block;
        transition: all .4s;

        @include hover-focus {
          color: $color-white;
          transform: translateY(-2px);
        }

        font-size: 14px;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  @include phone-up {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 5;

    .container {
      flex-direction: row-reverse;
      flex-wrap: nowrap;

      .menu-toggle {
        display: none;
      }

      nav {
        display: block !important;
        text-align: left;

        .menu {
          display: flex !important;
          position: inherit;
          width: inherit;
          background-color: transparent;
          padding: 0;

          .nav-link {
            color: $color-black;
            font-weight: 600;
            border-bottom: 0;
            padding: 0;
            margin-right: 60px;
            font-size: 18px;

            @include hover-focus {
              color: $color-white;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      .social {
        @include flex(row, inherit, center);

        a {
          font-size: 20px;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}